/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'inbox-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.98 4a.5.5 0 00-.39.188L1.54 8H6a.5.5 0 01.5.5 1.5 1.5 0 103 0A.5.5 0 0110 8h4.46l-3.05-3.812A.5.5 0 0011.02 4zm-1.17-.437A1.5 1.5 0 014.98 3h6.04a1.5 1.5 0 011.17.563l3.7 4.625a.5.5 0 01.106.374l-.39 3.124A1.5 1.5 0 0114.117 13H1.883a1.5 1.5 0 01-1.489-1.314l-.39-3.124a.5.5 0 01.106-.374z"/>',
    },
});
